import React, { useEffect, useState } from 'react';
import './OrderComponent.css';
import { Card, Text, Title, Grid, Col, Divider, Metric, Flex } from "@tremor/react";
import { FullOrder } from '../interfaces/OrderInterfaces';
import OrderDetailBlockComponent from './OrderDetailBlockComponent'

export default function OrderComponent({order}:{order:FullOrder|undefined}) {
    const [qrCodeSRC, setQrCodeSRC] = useState<string>();
        
    const relationshipTranslate: { [key: string]: string } = {
        "self": "Própria pessoa",
        "relative_or_friend": "Parente ou amigo",
        "doorman": "Portaria",
        "neighbor": "Vizinho"
    }

    const observationTypeTranslate: { [key: string]: string } = {
        "inaccessible": "Endereço fica em uma área inacessível",
        "vehicle_problems": "Problemas com veículo",
        "delivery_problems": "Problemas com a entrega",
        "wrong_address": "Endereco incorreto ou incompleto",
        "no_receiver": "Não há ninguem no endereco"
    }

    useEffect(()=>{
        if (!!order?.qr_code) {
            setQrCodeSRC(`data:image/png;base64,${order?.qr_code}`)
        } else if (!!order?.orderNumber) {
            const qrCodeAPIBaseURL = process.env.REACT_APP_QRCODE_API_URL;
            setQrCodeSRC(qrCodeAPIBaseURL + order?.orderNumber);
        }
    }, [order])

  return (
    <div className='OrderPage'>
        <Card className='OrderCard'>
            <Flex justifyContent='between'>
                <Flex justifyContent='start'>
                    <div>
                        <Title>ID do pedido</Title>
                        <Text className='max-w-md'>{order?.orderNumber}</Text>
                    </div>
                    <div className='NumNotaFiscal'>  
                        <Title>Número da nota fiscal</Title>
                        <Text className='max-w-md'>{order?.receiptNumber}</Text>
                    </div>
                </Flex>
                {(!!qrCodeSRC) ? <img className='QRCode' src={qrCodeSRC} /> : ""}
            </Flex>
            <Divider className='OrderDivider'/>
            <Metric>Dados do remetente</Metric>
            <br/>
            <OrderDetailBlockComponent order={order} sender={true} />
            <Divider className='OrderDivider'/>
            <Metric>Dados do destinatário</Metric>
            <br/>
            <OrderDetailBlockComponent order={order} sender={false} />
            <Divider className='OrderDivider'/>
            <Metric>Dados do pedido</Metric>
            <br/>
            <Grid numCols={6} className="gap-2">
                <Col numColSpan={1}>
                    <Title>Quantidade</Title>
                    <Text className='max-w-md'>{order?.amount}</Text>
                </Col>
                <Col numColSpan={1}>
                    <Title>Altura (cm)</Title>
                    <Text className='max-w-md'>{order?.height}</Text>
                </Col>
                <Col numColSpan={1}>
                    <Title>Largura (cm)</Title>
                    <Text className='max-w-md'>{order?.width}</Text>
                </Col>
                <Col numColSpan={1}>
                    <Title>Comprimento (cm)</Title>
                    <Text className='max-w-md'>{order?.length}</Text>                  
                </Col>
                <Col numColSpan={1}>
                    <Title>Peso (g)</Title>
                    <Text>{order?.weigth}</Text>
                </Col>
                <Col numColSpan={1}>
                    <Title>Valor da mercadoria</Title>
                    <Text>{order?.value}</Text>
                </Col>
            </Grid>
            <Divider className='OrderDivider'/>
            <Metric>Dados da entrega</Metric>
            <br/>
            <Grid numCols={3} className="gap-2">
                <Col numColSpan={1}>
                    <Title>Nome recebedor</Title>
                    <Text className='max-w-md'>{order?.collecterName}</Text>
                </Col>
                <Col numColSpan={1}>
                    <Title>Documento recebedor</Title>
                    <Text className='max-w-md'>{order?.collecterDocument}</Text>
                </Col>
                <Col numColSpan={1}>
                    <Title>Relacionamento recebedor</Title>
                    <Text className='max-w-md'>{
                        order?.collecterRelationship ?
                        relationshipTranslate[order?.collecterRelationship]
                        : ""
                    }</Text>
                </Col>
                <Col numColSpan={1}>
                    <Title>Classe da observação</Title>
                    <Text className='max-w-md'>{
                        order?.observationType ?
                        observationTypeTranslate[order?.observationType] :
                        ""
                    }</Text>
                </Col>
                <Col numColSpan={1}>
                    <Title>Observação tratamento</Title>
                    <Text className='max-w-md'>{order?.observation}</Text>
                </Col>
                <Col numColSpan={1}>
                    <Title>Logística reversa</Title>
                    <Text className='max-w-md'>{
                        order?.reverseLogistic ? "Sim" : "Não"
                    }</Text>
                </Col>
            </Grid>
        </Card>
    </div>
  );
};