import React, { useState } from 'react';
import './OrderCollectionPage.css';
import { 
    Card,
    Metric,
    Grid,
    Col,
    TextInput,
    Button,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow
 } from '@tremor/react';
import SidebarComponent from '../components/SidebarComponent';
import TitleComponent from "../components/TitleComponent";
import { TrashIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { useCookies } from 'react-cookie';

export default function OrderCollectionPage() {
    const [cookies] = useCookies(['mazelog-user']);
    const token = cookies['mazelog-user']?.token_data?.access_token;
    const oauth = "Bearer " + token;

    const [inputedOrder, setInputedOrder] = useState<string>("");
    const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
    const [selectedOrdersLength, setSelectedOrdersLength] = useState<number>(0);

    const handleSubmit = () => {
        const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;

        let URL = `${baseURL}/order/batch/status/collected`
        
        axios.put(
            URL,
            {data: selectedOrders},
            {headers: {accept: 'application/json', "Authorization": oauth}})
        .then(()=>{
          alert(`Status de ${selectedOrdersLength} pedidos atualizado com sucesso!`)
          setSelectedOrders([]);
          setSelectedOrdersLength(0);
          setInputedOrder("");
        })
        .catch((err)=>{
          const wrongOrder = err.response?.data?.detail[0]?.msg.split(" ")[3];
          const errorMessage = `Número do pedido '${wrongOrder}' está errado. Corrija ou remova ele.`
          console.log(errorMessage);
          alert(errorMessage);
        })
    }
    
    const addInputed2Selected = () => {
        if (inputedOrder !== "") {
            if (selectedOrders.includes(inputedOrder)) {
                setInputedOrder("");
            } else {
                setSelectedOrders(selectedOrders.concat(inputedOrder));
                setSelectedOrdersLength(selectedOrdersLength + 1);
                setInputedOrder("");
            }
        }
    }
    
    const handleKeypress = (e:any) => {
        if (e.keyCode === 13) {
          addInputed2Selected();
        }
      };

    const removeOrderFromList = (order: string) => {
        let array = selectedOrders;
        const index = array.indexOf(order);
        if (index > -1) {
            array.splice(index, 1);
        }
        setSelectedOrders(array);
        setSelectedOrdersLength(selectedOrdersLength - 1);
    }

  return (
    <main>
        <SidebarComponent />
        <div className='OrderContent'>
            <TitleComponent />
            <div className='DashboardViews'>
                <Card>
                    <Metric>Pedidos coletados: {selectedOrdersLength}</Metric>
                    <br/>
                    <Grid numCols={3} className="gap-2">
                        <Col numColSpan={1}>
                            <TextInput
                                className='max-w-md'
                                onChange={(e) => setInputedOrder(e.target.value)}
                                onKeyDown={handleKeypress}
                                value={inputedOrder}
                            />
                        </Col>
                        <Col numColSpan={1}>
                            <Button
                                className='AddOrderBtn'
                                disabled={inputedOrder === ""}
                                onClick={addInputed2Selected}
                            >Adicionar pedido</Button>
                        </Col>
                        <Col numColSpan={1}>
                            <Button
                                className='AddOrderBtn'
                                disabled={selectedOrders.length < 1}
                                onClick={handleSubmit}
                            >Enviar pedidos selecionados</Button>
                        </Col>
                    </Grid>
                    <Divider className='h-0.5'/>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell className="text-left">Pedido</TableHeaderCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            { selectedOrders
                                .map((order) => (
                                    <TableRow key={ order }>
                                        <TableCell
                                            className="TableField"
                                        >{ order }</TableCell>
                                        <Button
                                            className='RemoveSelectedOrderBtn'
                                            icon={TrashIcon}
                                            onClick={() => removeOrderFromList(order)}
                                        />
                                    </TableRow>
                                )) }
                        </TableBody>
                    </Table>
                </Card>
            </div>
        </div>
    </main>
  );
}