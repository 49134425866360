import React from 'react';
import './SidebarComponent.css';
import { Link, useLocation } from "react-router-dom";
import { useCookies } from 'react-cookie';
import {
  ArrowLeftOnRectangleIcon,
  MagnifyingGlassIcon,
  ExclamationTriangleIcon,
  InboxArrowDownIcon,
 } from '@heroicons/react/24/outline'

function SidebarComponent() {
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies(['mazelog-user']);

  function logout() {
    removeCookie("mazelog-user", {path: "/"});
  }

  return (
    <div className='Sidebar'>
      <Link to="/">
        <h1 className='SidebarTitle'>MAZELOG</h1>
      </Link>
      <div className='PagesList'>
        <Link to='/' className={location.pathname==='/'  ? 'SelectedPageButton' : 'PageButton'}>
          <MagnifyingGlassIcon className='SidebarIcon'/>Pesquisa
        </Link>
        <Link to='/occurrence' className={location.pathname==='/occurrence'  ? 'SelectedPageButton' : 'PageButton'}>
          <ExclamationTriangleIcon className='SidebarIcon'/>Ocorrências
        </Link>
        <Link to='/collection' className={location.pathname==='/collection'  ? 'SelectedPageButton' : 'PageButton'}>
          <InboxArrowDownIcon className='SidebarIcon'/>Coleta
        </Link>
      </div>
      <button className='PageButton' onClick={() => logout()}>
        <ArrowLeftOnRectangleIcon className='SidebarIcon'/>Logout
      </button>
    </div>
  );
}

export default SidebarComponent;