import React from 'react';
import './OccurrenceMessageComponent.css';
import { OccurrenceMessage } from '../../interfaces/OccurrenceInterfaces';
import {
    Card,
    Text,
    Title,
    Subtitle,
    Flex,
  } from "@tremor/react";

export default function OccurrenceMessageComponent(
    {message, viewerIdOrg}:{message:OccurrenceMessage, viewerIdOrg:number}
) {
    const convertDate = (date:Date) => {
        let newDate = new Date(date);
        newDate.setHours(newDate.getHours() - 3);
        let newDateStr = newDate.toLocaleString('en-GB');
        return newDateStr
    }

  return (
    <div className={message.id_org === viewerIdOrg ? "MyMessage" : "OthersMessage"}>
        <Card className="max-w-md mx-auto mt-5" decoration="top" decorationColor="violet">
            <div>
                <Subtitle>{message.username}</Subtitle>
                <Title>{message.content}</Title>
                <Text>{convertDate(message.time)}</Text>
            </div>
        </Card>
    </div>
  );
}