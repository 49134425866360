import React from 'react';
import './TitleComponent.css';
import { useCookies } from 'react-cookie';

export default function TitleComponent() {
  const [cookies, setCookie] = useCookies(['mazelog-user']);
  const username = cookies['mazelog-user']?.user_data?.username;

  return (
    <div>
      <div className="TitleBlock">
        <h1 className="Greeting">Olá, {username}</h1>
      </div>
      <hr/>
    </div>
  );
}