import React, { useEffect, useState } from 'react';
import LoginPage from './pages/LoginPage';
import StickerPage from './pages/StickerPage';
import SearchPage from './pages/SearchPage';
import OrderDetailPage from './pages/OrderDetailPage';
import OrderCollectionPage from './pages/OrderCollectionPage';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { Drivers } from './interfaces/UserInterfaces';
import OccurrenceListPage from './pages/Occurrences/OccurrenceListPage';
import OccurrenceChatPage from './pages/Occurrences/OccurrenceChatPage';

export default function App() {
  const [cookies] = useCookies(['mazelog-user']);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [drivers, setDrivers] = useState<Drivers>({});

  const getTransporters = () => {
    const baseURL = process.env.REACT_APP_MAZELOG_AUTH_BASE_URL;
    axios.get(`${baseURL}/users/transporters`,
    {headers: {"Authorization": "Bearer " + cookies["mazelog-user"]?.token_data?.access_token}})
    .then((res) => {
      setDrivers(res.data.transporters)
    })
    .catch(err =>
      console.log(err)
    )
  }

  useEffect(()=>{
    setIsLoggedIn(['CORP','TRANSPORTER'].includes(cookies["mazelog-user"]?.organization_data?.profile));
    getTransporters();
    console.log(drivers);
  },[cookies])

  return (
    <div  className="App">
      {isLoggedIn ? 
      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/orders" element={<StickerPage orderIds={selectedOrders}/>} />

          <Route path="/" element={
            <SearchPage
              selectedOrders={selectedOrders}
              setSelectedOrders={setSelectedOrders}
              drivers={drivers}
            />} />

          <Route path="/order/:id" element={<OrderDetailPage drivers={drivers}/>} />

          <Route path="/occurrence" element={<OccurrenceListPage />} />

          <Route path='/occurrence/:id' element={<OccurrenceChatPage />} />

          <Route path='/collection' element={<OrderCollectionPage />} />

          {/*<Route path="*" element={<NotFound />} />*/}
        </Routes>
      </Router>
      :
      <LoginPage/>
      }
    </div>
  );
}
