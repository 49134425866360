import React, { useEffect, useState } from 'react';
import './OccurrenceChatPage.css';
import { useParams, Link } from 'react-router-dom';
import SidebarComponent from '../../components/SidebarComponent';
import TitleComponent from '../../components/TitleComponent';
import {
  Card,
  TextInput,
  Button,
  Flex,
  Callout,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "@tremor/react";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { OrderOccurrence, OccurrenceStatus } from '../../interfaces/OccurrenceInterfaces';
import OccurrenceMessageComponent from '../../components/occurrenceComponents/OccurrenceMessageComponent';

export default function OccurrenceChatPage() {
  const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;
  let { id } = useParams();
  const [orderOccurrence, setOrderOccurrence] = useState<OrderOccurrence>();
  const [buttonsDisabeled, setButtonsDisabeled] = useState<boolean>(true);
  const [orderUpdated, setOrderUpdated] = useState<boolean>(false);
  const [messageContent, setMessageContent] = useState<string>();

  const [cookies] = useCookies(['mazelog-user']);
  const token = cookies['mazelog-user']?.token_data?.access_token;
  const oauth = "Bearer " + token;
  const viewerIdOrg = cookies['mazelog-user']?.organization_data?.id_org;

  const resolveOccurrence = () => {
    if (window.confirm("Deseja mesmo concluir esta ocorrência?")){
      axios.post(`${baseURL}/order/occurrences/${id}/resolve`,{},{headers: {"Authorization": oauth}})
      .then((res) => {
          console.log(res);
          setOrderUpdated(!orderUpdated);
      })
      .catch((err) => {
          console.log(err);
      })
    }
  }

  const cancelOccurrence = () => {
    if (window.confirm("Deseja mesmo cancelar esta ocorrência?")){
      axios.delete(`${baseURL}/order/occurrences/${id}`,{headers: {"Authorization": oauth}})
      .then((res) => {
          console.log(res);
          setOrderUpdated(!orderUpdated);
      })
      .catch((err) => {
          console.log(err);
      })
    }
  }

  const getOccurrenceData = () => {
    axios.get(`${baseURL}/order/occurrences/${id}`,{headers: {"Authorization": oauth}})
      .then((res) => {
          console.log(res);
          setOrderOccurrence(res.data);
          setButtonsDisabeled(res.data?.status !== OccurrenceStatus.OPEN);
      })
      .catch((err) => {
          console.log(err);
      })
  }

  const sendMessage = () => {
    axios.post(`${baseURL}/order/occurrences/${id}`,
      {content: messageContent},
      {headers: {"Authorization": oauth}})
    .then((res) => {
        console.log(res);
        setOrderUpdated(!orderUpdated);
        setMessageContent("");
    })
    .catch((err) => {
        console.log(err);
    })
  }

  const handleKeypress = (e:any) => {
    if (e.keyCode === 13) {
      sendMessage();
    }
  };

  useEffect(()=>{
    getOccurrenceData();
  },[id, orderUpdated])

  const convertDate = (date:Date) => {
    let newDate = new Date(date);
    newDate.setHours(newDate.getHours() - 3);
    let newDateStr = newDate.toLocaleString('en-GB').substring(0, 10);
    return newDateStr
  }

  const truncateString = (text:string, maxLength: number):string => {
    if (text.length <= maxLength) {
      return text
    } else {
      return text.substring(0, maxLength) + '...'
    }
  }

  const statusTranslate: { [key: string]: string } = {
    "created": "Criado",
    "transporter_allocated": "Transportador alocado",
    "collected": "Coletado",
    "received": "Recebido",
    "en_route": "Em rota",
    "delivered": "Entregue",
    "cancelled": "Cancelado",
    "handling": "Em tratativa",
    "awaiting_return": "Aguard. devolução",
    "awaiting_transfer": "Aguard. transferência",
    "return_route": "Rota devolução",
    "returned": "Devolvido",
    "collected_not_received": "Coletados Não Receb.",
    "cancelled_after_received": "Canc. após Receb.",
    "returned_area_not_serviced": "Devolvido - Área não atendida",
    "new_driver_allocated": "Novo entregador alocado"
}

  return (
    <main className="Page">
      <SidebarComponent />
      <div className="Content">
        <TitleComponent />
        <Flex justifyContent='end'>
          <Link to={`/order/${id}`} target="_blank" rel="noopener noreferrer">
            <Button className='DownloadBtn'>
              Abrir detalhes do pedido
            </Button>
          </Link>
          <Button
            disabled={buttonsDisabeled}
            onClick={resolveOccurrence}
            className='DownloadBtn'>Concluir ocorrência</Button>
          <Button
            disabled={buttonsDisabeled}
            onClick={cancelOccurrence}
            className='DownloadBtn'>Cancelar ocorrência</Button>
        </Flex>
        <div className="DashboardViews">
          <Card>
          {orderOccurrence?.status === OccurrenceStatus.OPEN ?
            <Callout title='Status' color='yellow'>Ocorrência em aberto</Callout>
            : ""
          }
          {orderOccurrence?.status === OccurrenceStatus.CANCELLED ?
            <Callout title='Status' color='rose'>
              Ocorrência cancelada (para reabrir, basta enviar uma nova mensagem)
            </Callout>
            : ""
          }
          {orderOccurrence?.status === OccurrenceStatus.SOLVED ?
            <Callout title='Status' color='teal'>
              Ocorrência concluída (para reabrir, basta enviar uma nova mensagem)
            </Callout>
            : ""
          }
          {!orderOccurrence ?
            <Callout title='Nova ocorrência'>
              Crie uma ocorrência para este pedido.
              Envie uma mensagem no campo abaixo e forneça o máximo de detalhes que puder.
            </Callout>
            : ""
          }

          {!orderOccurrence?.order_details ? "" :
          <Table className='mt-5'>
            <TableHead>
              <TableRow>
                <TableHeaderCell>ID do pedido</TableHeaderCell>
                <TableHeaderCell className="text-left">Número nota</TableHeaderCell>
                <TableHeaderCell className="text-left">Data criação</TableHeaderCell>
                <TableHeaderCell className="text-left">Status</TableHeaderCell>
                <TableHeaderCell className="text-left">Remetente</TableHeaderCell>
                <TableHeaderCell className="text-left">Destinatário</TableHeaderCell>
                <TableHeaderCell className="text-left">CEP Dest</TableHeaderCell>
                <TableHeaderCell className="text-left">Transportador</TableHeaderCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow key={ id }>
                <TableCell className="TableField">{ orderOccurrence.order_details.orderNumber }</TableCell>
                <TableCell className="TableField">{ orderOccurrence.order_details.receiptNumber }</TableCell>
                <TableCell className="TableField">{ convertDate(orderOccurrence.order_details.creationDate) }</TableCell>
                <TableCell className="TableField">{ statusTranslate[orderOccurrence.order_details.status] }</TableCell>
                <TableCell className="TableField">{ truncateString(orderOccurrence.order_details.sender, 20) }</TableCell>
                <TableCell className="TableField">{ truncateString(orderOccurrence.order_details.receiver, 20) }</TableCell>
                <TableCell className="TableField">{ orderOccurrence.order_details.receiverZIP }</TableCell>
                <TableCell className="TableField">{ orderOccurrence.order_details.transporter }</TableCell>
              </TableRow>
            </TableBody>
          </Table>}

            <Card className='MessageBackground'>
              <Flex flexDirection='col'>
              { !!orderOccurrence ?
               orderOccurrence.messages
                .map((message) => (
                    <OccurrenceMessageComponent
                      key={orderOccurrence.messages.findIndex(i => i === message)}
                      message={message} viewerIdOrg={viewerIdOrg}/>
                )) :
                "" }
              </Flex>
            </Card>
            <Flex justifyContent="between" className='mt-2.5'>
              <TextInput
                className='mt-2.5 mr-1'
                placeholder='Digite sua resposta...'
                value={messageContent}
                onChange={(e) => setMessageContent(e.target.value)}
                onKeyDown={handleKeypress}
              />
              <Button
                onClick={sendMessage}
                className='SendMessageBtn'
              >Enviar mensagem</Button>
            </Flex>
          </Card>
        </div>
      </div>
    </main>
  );
}