import { Page, View, Document, Text, Image, StyleSheet, Svg, Line, Font } from '@react-pdf/renderer';
import { FullOrder } from '../interfaces/OrderInterfaces';
import inter from '../fonts/Inter-SemiBold.ttf';

Font.register({
    family: "Inter",
    format: "truetype",
    src: inter 
  });

const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#fff',
      padding: 15,
      alignContent: 'space-between',
      justifyContent: 'space-between',
    },
    section: {
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignContent: 'space-between',
      justifyContent: 'space-between',
    },
    midSection: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'space-between',
        justifyContent: 'space-between',
      },
    qrCode: {
        height: 100,
        width: 100,
        padding: 10,
    },
    logo: {
        width: 100,
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 20,
        marginRight: 10,
    },
    titles: {
        fontSize: 11,
    },
    orderDetail: {
        fontSize: 11,
        flexGrow: 1,
    },
    orderReceiptDetail: {
        fontSize: 11,
        flexGrow: 1,
        marginLeft: 116
    },
    text: {
        fontSize: 7.5,
        fontFamily: 'Inter',
        flexGrow: 1,
        marginBottom: 5,
    },
    lastLineText: {
        fontSize: 7.5,
        fontFamily: 'Inter',
        flexGrow: 1,
    },
    orderNumber: {
        fontSize: 11,
        fontFamily: 'Inter',
        flexGrow: 1,
        marginBottom: 10,
    },
    creationDate: {
        fontSize: 11,
        fontFamily: 'Inter',
        flexGrow: 1,
        marginLeft: 100,
    }
  });

  const titleHandler = (orderList:FullOrder[]) => {
    if(orderList.length === 1){
        return orderList[0]?.orderNumber
    } else {
        return 'notas'
    }
  }

  const convertDate = (date:string) => {
    let newDate = new Date(date);
    newDate.setHours(newDate.getHours() - 3);
    let newDateStr = newDate.toLocaleString('en-GB').substring(0, 10);
    return newDateStr
  }

export default function PDFStickerComponent(
    {orderList, productFlag}:{orderList:FullOrder[], productFlag:boolean}) {
        const retrieveQRCode = (order: FullOrder) => {
            if (!!order?.qr_code) {
                return `data:image/png;base64,${order?.qr_code}`
            } else if (!! order?.orderNumber) {
                const qrCodeAPIBaseURL = process.env.REACT_APP_QRCODE_API_URL as String;
                return qrCodeAPIBaseURL + order.orderNumber
            } else {
                return undefined
            }
        }

    return (
        <Document pageMode='fullScreen' title={`${titleHandler(orderList)}.pdf`}>
            {orderList.map((order:FullOrder)=>(
            <Page size="A6" style={styles.page}>
                <Text style={styles.titles}>Destinatário</Text>
                <View style={styles.section}>
                    <Text style={styles.text} >{order?.receiver.toUpperCase()}</Text>
                    <Text style={styles.text} >TEL: {order?.receiverTelephone}</Text>
                    <Text style={styles.text} >{order?.receiverStreet.toUpperCase() +
                                                (order?.receiverStreetNumber ?  ', ' + order?.receiverStreetNumber.toUpperCase() : '') + 
                                                (order?.receiverAddressNumber ? ', ' + order?.receiverAddressNumber.toUpperCase() : '')}</Text>
                    <Text style={styles.text} >{!order?.receiverNeighborhood ?
                                                order?.receiverCity.toUpperCase() + ', ' + order?.receiverState.toUpperCase() :
                                                order?.receiverNeighborhood.toUpperCase() + ', ' + order?.receiverCity.toUpperCase() + ', ' + order?.receiverState.toUpperCase()}</Text>
                    <Text style={styles.lastLineText} >CEP: {order?.receiverZIP}</Text>
                </View>
                <Svg height="10" width="495"><Line x1="0" y1="5" x2="265" y2="5" strokeWidth={2} stroke="rgb(0, 0, 0)" /></Svg>
                <View style={styles.midSection}>
                    <Text style={styles.orderDetail} >ID do pedido</Text>
                    <Text style={styles.orderReceiptDetail} >Nota fiscal</Text>
                    <Text style={styles.orderNumber}>{order?.orderNumber}</Text>
                    <Text style={styles.orderNumber}>{order?.receiptNumber ? order?.receiptNumber : '        '}</Text>
                    <Image style={styles.qrCode} src={retrieveQRCode(order)}/>
                    <Image style={styles.logo} src={require('../images/tag_el.png')}/>
                    <Text style={styles.orderDetail}>{"Data do pedido"}</Text>
                    <Text style={styles.creationDate}>{convertDate(order?.events[0].date)}</Text>
                </View>
                <Svg height="10" width="495"><Line x1="0" y1="5" x2="265" y2="5" strokeWidth={2} stroke="rgb(0, 0, 0)" /></Svg>
                <Text style={styles.titles}>Remetente</Text>
                <View style={styles.section}>
                    <Text style={styles.text}>{order?.sender.toUpperCase()}</Text>
                    <Text style={styles.text}>TEL: {order?.senderTelephone}</Text>
                    <Text style={styles.text}>{order?.senderStreet.toUpperCase() +
                                              (order?.senderStreetNumber ?  ', ' + order?.senderStreetNumber.toUpperCase() : '') + 
                                              (order?.senderAddressNumber ? ', ' + order?.senderAddressNumber.toUpperCase() : '')}</Text>
                    <Text style={styles.text}>{!order?.senderNeighborhood ?
                                                order?.senderCity.toUpperCase() + ', ' + order?.senderState.toUpperCase() :
                                                order?.senderNeighborhood.toUpperCase() + ', ' + order?.senderCity.toUpperCase() + ', ' + order?.senderState.toUpperCase()}</Text>
                    <Text style={styles.lastLineText} >CEP: {order?.senderZIP}</Text>
                </View>
                {
                    !productFlag ? "" :
                    (<div>
                        <Svg height="10" width="495"><Line x1="0" y1="5" x2="265" y2="5" strokeWidth={2} stroke="rgb(0, 0, 0)" /></Svg>
                        <View style={styles.section}>
                            <Text style={styles.text}>{order?.product.toUpperCase() + ', UN, ' + order?.amount.toUpperCase()}</Text>
                        </View>
                    </div>)
                }
            </Page>))}
        </Document>
    )
}