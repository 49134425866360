import { ShortOrder } from "./OrderInterfaces";

export enum OccurrenceStatus {
    OPEN = 'open',
    CANCELLED = 'cancelled',
    SOLVED = 'solved',
};

export type OccurrenceMessage = {
    id_org: number;
    id_user: number;
    username: string;
    time: Date;
    content: string;
}


export type OrderOccurrence = {
    order_details: ShortOrder;
    status: OccurrenceStatus
    open_time: Date;
    close_time?: Date;
    messages: OccurrenceMessage[];
}